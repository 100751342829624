.login {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 50vh;
}

.bg {
  display: flex;
  color: whitesmoke;
  -webkit-text-stroke: 2px black;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: row;
}

.flex-grid {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  flex-direction: row;
}
